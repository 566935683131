<template>
  <el-dialog
      title="修改公司"
      width="35%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row>
        <el-col :span="18">
          <el-form-item label="公司名称" prop="tenantName">
            <el-input v-model="form.tenantName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
            <el-form-item label="所在城市" prop="tenantCity">
              <el-input v-model="form.tenantCity"></el-input>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="联系人" prop="tenantCorporation">
            <el-input v-model="form.tenantCorporation"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="手机号" prop="tenantPhone">
            <el-input v-model="form.tenantPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="公司等级" prop="tenantGrade">
            <el-select v-model="form.tenantGrade" placeholder="课程类型" style="width: 100%">
              <el-option
                  v-for="item in gradeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="editTenantCheck()">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "tenant-edit",
  data() {
    return {
      form: {
        id: this.tenant.id,
        tenantName: this.tenant.tenantName,
        tenantCity: this.tenant.tenantCity,
        tenantCorporation: this.tenant.tenantCorporation,
        tenantPhone: this.tenant.tenantPhone,
        tenantGrade: this.tenant.tenantGrade,
      },
      rules: {
        tenantName: [
          {required: true, message: '请输入公司姓名', trigger: 'blur'},
        ],
        tenantCity: [
          {required: true, message: '请输入公司城市', trigger: 'blur'},
        ],
        tenantCorporation: [
          {required: true, message: '请输入联系人', trigger: 'blur'},
        ],
        tenantPhone: [
          {required: true, message: '联系人手机号', trigger: 'blur'},
        ],
        tenantGrade: [
          {required: true, message: '请输入等级', trigger: 'blur'},
        ],
      },
      dialogState: this.state,
      gradeList:[]
    }
  },
  props: {
    tenant: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.queryProductGradeIds()
  },
  methods: {
    queryProductGradeIds() {
      this.$selectUtils.queryProductGradeIds().then(response => {
        this.gradeList = response.data.data
      })
    },
    editTenantCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.editTenant();
        }
      })
    },
    //添加用户
    editTenant: function () {
      let value = "是否修改公司:" + this.form.tenantName
      this.$confirm(value, '修改公司:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/tenant/updateTenant",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>